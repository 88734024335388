import React from 'react'

export const UserProfileGroup = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3333 18.0072C22.8061 18.0072 24 16.8133 24 15.3405C24 13.8677 22.8061 12.6738 21.3333 12.6738C19.8606 12.6738 18.6667 13.8677 18.6667 15.3405C18.6667 16.8133 19.8606 18.0072 21.3333 18.0072Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6667 4H9.33333C6.38781 4 4 6.38781 4 9.33333V22.6667C4 25.6122 6.38781 28 9.33333 28H22.6667C25.6122 28 28 25.6122 28 22.6667V9.33333C28 6.38781 25.6122 4 22.6667 4Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18.0085C13.841 18.0085 15.3333 16.5161 15.3333 14.6751C15.3333 12.8342 13.841 11.3418 12 11.3418C10.1591 11.3418 8.66667 12.8342 8.66667 14.6751C8.66667 16.5161 10.1591 18.0085 12 18.0085Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3333 28V26.6667C19.3333 25.429 18.8417 24.242 17.9665 23.3668C17.0913 22.4917 15.9043 22 14.6667 22H9.40667C8.3581 22.0015 7.34073 22.3569 6.51923 23.0085C5.69773 23.6602 5.12016 24.57 4.88 25.5907"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3333 22.0078H23.3413C24.2376 22.0103 25.1138 22.2731 25.8634 22.7643C26.6131 23.2554 27.2039 23.9538 27.564 24.7745"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
