import React from 'react'
import { Colors } from 'utils/colors'

export const Headset = props => {
  const { color = Colors.Green } = props || {}
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3337 22.6666H24.0003C23.6467 22.6666 23.3076 22.5261 23.0575 22.2761C22.8075 22.026 22.667 21.6869 22.667 21.3333V14.6666C22.667 14.313 22.8075 13.9738 23.0575 13.7238C23.3076 13.4737 23.6467 13.3333 24.0003 13.3333H25.3337C26.0409 13.3333 26.7192 13.6142 27.2193 14.1143C27.7194 14.6144 28.0003 15.2927 28.0003 15.9999V19.9999C28.0003 20.7072 27.7194 21.3854 27.2193 21.8855C26.7192 22.3856 26.0409 22.6666 25.3337 22.6666Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 22.6666H6.66667C5.95942 22.6666 5.28115 22.3856 4.78105 21.8855C4.28095 21.3854 4 20.7072 4 19.9999V15.9999C4 15.2927 4.28095 14.6144 4.78105 14.1143C5.28115 13.6142 5.95942 13.3333 6.66667 13.3333H8C8.35362 13.3333 8.69276 13.4737 8.94281 13.7238C9.19286 13.9738 9.33333 14.313 9.33333 14.6666V21.3333C9.33333 21.6869 9.19286 22.026 8.94281 22.2761C8.69276 22.5261 8.35362 22.6666 8 22.6666Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6663 13.3333V12.6667C24.6663 10.3681 23.7532 8.16372 22.1279 6.53841C20.5026 4.91309 18.2982 4 15.9997 4C13.7011 4 11.4967 4.91309 9.87142 6.53841C8.2461 8.16372 7.33301 10.3681 7.33301 12.6667V13.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8333 28.3333H15.1667C14.7246 28.3333 14.3007 28.1577 13.9882 27.8452C13.6756 27.5326 13.5 27.1087 13.5 26.6667C13.5 26.2246 13.6756 25.8007 13.9882 25.4882C14.3007 25.1756 14.7246 25 15.1667 25H16.8333C17.2754 25 17.6993 25.1756 18.0118 25.4882C18.3244 25.8007 18.5 26.2246 18.5 26.6667C18.5 27.1087 18.3244 27.5326 18.0118 27.8452C17.6993 28.1577 17.2754 28.3333 16.8333 28.3333V28.3333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 26.6667H21.3333C22.0406 26.6667 22.7189 26.3858 23.219 25.8857C23.719 25.3856 24 24.7073 24 24.0001V22.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
