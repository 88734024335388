import React from 'react'

export const Lens = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M22.667 22.667l-5.45-5.5M18.061 5.333h7.272A2.667 2.667 0 0128 8v17.333A2.667 2.667 0 0125.333 28H8a2.667 2.667 0 01-2.667-2.667V15.295M8.413 8.84h7.334M8.413 12.84h5.191"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12.11 18.95a8.11 8.11 0 100-16.22 8.11 8.11 0 000 16.22z"
    />
  </svg>
)
