import React from 'react'

// eslint-disable-next-line max-lines-per-function
export const CalendarAdd = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clipPath="url(#clip0_62_1446)">
      <path
        d="M20 4V6.66667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 4V6.66667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 10.6666H25.3333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3333 16.5559V7.99992C25.3333 7.29267 25.0524 6.6144 24.5523 6.1143C24.0522 5.6142 23.3739 5.33325 22.6667 5.33325H6.66667C5.95942 5.33325 5.28115 5.6142 4.78105 6.1143C4.28095 6.6144 4 7.29267 4 7.99992V22.6666C4 23.3738 4.28095 24.0521 4.78105 24.5522C5.28115 25.0523 5.95942 25.3333 6.66667 25.3333H16.556"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.332 14.3334C9.26613 14.3336 9.20182 14.3534 9.14717 14.3902C9.09253 14.427 9.05002 14.4791 9.02499 14.5401C8.99997 14.601 8.99356 14.668 9.00657 14.7326C9.01958 14.7971 9.05143 14.8564 9.0981 14.9029C9.14477 14.9494 9.20417 14.981 9.26879 14.9937C9.33342 15.0065 9.40037 14.9998 9.4612 14.9745C9.52203 14.9493 9.57401 14.9065 9.61058 14.8518C9.64715 14.797 9.66667 14.7326 9.66667 14.6667C9.66667 14.6228 9.658 14.5794 9.64117 14.5388C9.62433 14.4983 9.59966 14.4615 9.56856 14.4305C9.53747 14.3996 9.50057 14.375 9.45997 14.3584C9.41937 14.3417 9.37589 14.3332 9.332 14.3334Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.332 19.6666C9.26613 19.6669 9.20182 19.6867 9.14717 19.7235C9.09253 19.7602 9.05002 19.8124 9.02499 19.8733C8.99997 19.9343 8.99356 20.0012 9.00657 20.0658C9.01958 20.1304 9.05143 20.1896 9.0981 20.2361C9.14477 20.2826 9.20417 20.3142 9.26879 20.327C9.33342 20.3397 9.40037 20.3331 9.4612 20.3078C9.52203 20.2825 9.57401 20.2398 9.61058 20.185C9.64715 20.1302 9.66667 20.0658 9.66667 20C9.66667 19.9561 9.658 19.9126 9.64117 19.8721C9.62433 19.8316 9.59966 19.7948 9.56856 19.7638C9.53747 19.7328 9.50057 19.7083 9.45997 19.6916C9.41937 19.6749 9.37589 19.6665 9.332 19.6666Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3335 14.668C14.3338 14.7339 14.3535 14.7982 14.3903 14.8529C14.4271 14.9075 14.4793 14.95 14.5402 14.975C14.6011 15.0001 14.6681 15.0065 14.7327 14.9935C14.7972 14.9805 14.8565 14.9486 14.903 14.9019C14.9495 14.8553 14.9811 14.7959 14.9939 14.7312C15.0066 14.6666 14.9999 14.5997 14.9747 14.5388C14.9494 14.478 14.9067 14.426 14.8519 14.3895C14.7971 14.3529 14.7327 14.3334 14.6668 14.3334C14.6229 14.3334 14.5795 14.342 14.539 14.3589C14.4984 14.3757 14.4616 14.4004 14.4307 14.4315C14.3997 14.4626 14.3752 14.4995 14.3585 14.5401C14.3418 14.5807 14.3333 14.6242 14.3335 14.668Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6667 29.3333C20.8986 29.3333 19.2029 28.631 17.9526 27.3807C16.7024 26.1305 16 24.4348 16 22.6667C16 19.0613 19.0667 15.996 22.672 16C24.4401 16.0007 26.1355 16.7038 27.3853 17.9545C28.635 19.2053 29.3367 20.9012 29.336 22.6693C29.3353 24.4374 28.6322 26.1329 27.3815 27.3826C26.1307 28.6323 24.4348 29.334 22.6667 29.3333Z"
        stroke={color}
      />
      <path
        d="M22.6665 20V25.3333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3333 22.6666H20"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_62_1446">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
