import React from 'react'

export const BarGraphSquare = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 9.33333V22.6667C28 24.0812 27.4381 25.4377 26.4379 26.4379C25.4377 27.4381 24.0812 28 22.6667 28H9.33333C7.91885 28 6.56229 27.4381 5.5621 26.4379C4.5619 25.4377 4 24.0812 4 22.6667V9.33333C4 7.91885 4.5619 6.56229 5.5621 5.5621C6.56229 4.5619 7.91885 4 9.33333 4H22.6667C24.0812 4 25.4377 4.5619 26.4379 5.5621C27.4381 6.56229 28 7.91885 28 9.33333Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7773 21.556V17.112C13.7774 16.966 13.7488 16.8215 13.6931 16.6866C13.6374 16.5517 13.5556 16.4291 13.4524 16.3258C13.3493 16.2225 13.2268 16.1406 13.0919 16.0847C12.9571 16.0288 12.8126 16 12.6666 16H10.4439C10.1494 16 9.86685 16.117 9.65856 16.3253C9.45027 16.5336 9.33325 16.8161 9.33325 17.1107V21.4133"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2226 21.556V11.556C18.2226 11.2614 18.1055 10.9789 17.8973 10.7706C17.689 10.5623 17.4065 10.4453 17.1119 10.4453H14.8892C14.5947 10.4453 14.3122 10.5623 14.1039 10.7706C13.8956 10.9789 13.7786 11.2614 13.7786 11.556V21.556"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6667 21.4147V14.3333C22.6667 14.0388 22.5496 13.7563 22.3413 13.548C22.1331 13.3397 21.8506 13.2227 21.556 13.2227H19.3333C19.0388 13.2227 18.7563 13.3397 18.548 13.548C18.3397 13.7563 18.2227 14.0388 18.2227 14.3333V21.556"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6666 21.556H9.33325"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
