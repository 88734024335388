import React from 'react'

export const Document = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clipPath="url(#clip0_61_2462)">
      <path
        d="M24.5518 8.552L20.7812 4.78133C20.2812 4.2812 19.603 4.00015 18.8958 4H9.33317C8.62593 4 7.94765 4.28095 7.44755 4.78105C6.94746 5.28115 6.6665 5.95942 6.6665 6.66667V25.3333C6.6665 26.0406 6.94746 26.7189 7.44755 27.219C7.94765 27.719 8.62593 28 9.33317 28H22.6665C23.3737 28 24.052 27.719 24.5521 27.219C25.0522 26.7189 25.3332 26.0406 25.3332 25.3333V10.4373C25.333 9.73015 25.052 9.05198 24.5518 8.552V8.552Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6665 16.3066H21.3332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6665 20.3066H21.3332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6665 24H17.3332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6665 11.0013V8.99867C10.6665 8.44667 11.1132 8 11.6652 8H13.6678C14.2198 8 14.6665 8.44667 14.6665 8.99867V11.0013C14.6665 11.2662 14.5613 11.5202 14.374 11.7075C14.1867 11.8948 13.9327 12 13.6678 12H11.6652C11.4003 12 11.1463 11.8948 10.959 11.7075C10.7717 11.5202 10.6665 11.2662 10.6665 11.0013Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_61_2462">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
