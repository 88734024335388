import React from 'react'

// eslint-disable-next-line max-lines-per-function
export const CalendarArrow = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.564 28H8C6.93913 28 5.92172 27.5786 5.17157 26.8284C4.42143 26.0783 4 25.0609 4 24V8C4 6.93913 4.42143 5.92172 5.17157 5.17157C5.92172 4.42143 6.93913 4 8 4H24C25.0609 4 26.0783 4.42143 26.8284 5.17157C27.5786 5.92172 28 6.93913 28 8V19.564"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3335 17.333C24.5202 17.333 25.6802 17.6849 26.6669 18.3442C27.6536 19.0035 28.4226 19.9406 28.8768 21.0369C29.3309 22.1333 29.4497 23.3397 29.2182 24.5035C28.9867 25.6674 28.4153 26.7365 27.5761 27.5756C26.737 28.4148 25.6679 28.9862 24.504 29.2177C23.3402 29.4492 22.1338 29.3304 21.0374 28.8763C19.941 28.4222 19.004 27.6531 18.3447 26.6664C17.6854 25.6797 17.3335 24.5197 17.3335 23.333C17.3335 21.7417 17.9656 20.2156 19.0909 19.0904C20.2161 17.9651 21.7422 17.333 23.3335 17.333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6665 21.333L24.6665 23.333L22.6665 25.333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 10.667H4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99844 15.2666C8.99003 15.2664 8.98168 15.2679 8.97384 15.271C8.96601 15.274 8.95886 15.2786 8.95279 15.2844C8.94672 15.2902 8.94186 15.2972 8.93848 15.3049C8.93511 15.3126 8.93328 15.3209 8.93311 15.3293V15.3333C8.93311 15.3462 8.93694 15.3588 8.94412 15.3696C8.9513 15.3803 8.9615 15.3887 8.97344 15.3936C8.98537 15.3986 8.99851 15.3999 9.01118 15.3974C9.02386 15.3948 9.0355 15.3886 9.04464 15.3795C9.05377 15.3703 9.06 15.3587 9.06252 15.346C9.06504 15.3334 9.06374 15.3202 9.0588 15.3083C9.05385 15.2963 9.04548 15.2861 9.03474 15.279C9.02399 15.2718 9.01136 15.2679 8.99844 15.2679H8.99711"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.06546 15.3333C9.06546 15.3465 9.06155 15.3593 9.05423 15.3703C9.0469 15.3813 9.03649 15.3898 9.02431 15.3949C9.01213 15.3999 8.99872 15.4012 8.98579 15.3987C8.97286 15.3961 8.96098 15.3897 8.95166 15.3804C8.94233 15.3711 8.93598 15.3592 8.93341 15.3463C8.93084 15.3333 8.93216 15.3199 8.9372 15.3078C8.94225 15.2956 8.95079 15.2852 8.96176 15.2778C8.97272 15.2705 8.98561 15.2666 8.9988 15.2666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99854 15.2666C9.01622 15.2666 9.03317 15.2736 9.04568 15.2861C9.05818 15.2986 9.0652 15.3156 9.0652 15.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7334 15.3333C13.7334 15.3465 13.7295 15.3593 13.7222 15.3703C13.7149 15.3813 13.7045 15.3898 13.6923 15.3949C13.6801 15.3999 13.6667 15.4012 13.6538 15.3987C13.6408 15.3961 13.6289 15.3897 13.6196 15.3804C13.6103 15.3711 13.604 15.3592 13.6014 15.3463C13.5988 15.3333 13.6001 15.3199 13.6052 15.3078C13.6102 15.2956 13.6188 15.2852 13.6297 15.2778C13.6407 15.2705 13.6536 15.2666 13.6668 15.2666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6665 15.2666C13.6842 15.2666 13.7011 15.2736 13.7136 15.2861C13.7261 15.2986 13.7332 15.3156 13.7332 15.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99844 19.2666C8.99003 19.2664 8.98168 19.2679 8.97384 19.271C8.96601 19.274 8.95886 19.2786 8.95279 19.2844C8.94672 19.2902 8.94186 19.2972 8.93848 19.3049C8.93511 19.3126 8.93328 19.3209 8.93311 19.3293V19.3333C8.93311 19.3462 8.93694 19.3588 8.94412 19.3696C8.9513 19.3803 8.9615 19.3887 8.97344 19.3936C8.98537 19.3986 8.99851 19.3999 9.01118 19.3974C9.02386 19.3948 9.0355 19.3886 9.04464 19.3795C9.05377 19.3703 9.06 19.3587 9.06252 19.346C9.06504 19.3334 9.06374 19.3202 9.0588 19.3083C9.05385 19.2963 9.04548 19.2861 9.03474 19.279C9.02399 19.2718 9.01136 19.2679 8.99844 19.2679H8.99711"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.06546 19.3333C9.06546 19.3465 9.06155 19.3593 9.05423 19.3703C9.0469 19.3813 9.03649 19.3898 9.02431 19.3949C9.01213 19.3999 8.99872 19.4012 8.98579 19.3987C8.97286 19.3961 8.96098 19.3897 8.95166 19.3804C8.94233 19.3711 8.93598 19.3592 8.93341 19.3463C8.93084 19.3333 8.93216 19.3199 8.9372 19.3078C8.94225 19.2956 8.95079 19.2852 8.96176 19.2778C8.97272 19.2705 8.98561 19.2666 8.9988 19.2666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99854 19.2666C9.01622 19.2666 9.03317 19.2736 9.04568 19.2861C9.05818 19.2986 9.0652 19.3156 9.0652 19.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7334 19.3333C13.7334 19.3465 13.7295 19.3593 13.7222 19.3703C13.7149 19.3813 13.7045 19.3898 13.6923 19.3949C13.6801 19.3999 13.6667 19.4012 13.6538 19.3987C13.6408 19.3961 13.6289 19.3897 13.6196 19.3804C13.6103 19.3711 13.604 19.3592 13.6014 19.3463C13.5988 19.3333 13.6001 19.3199 13.6052 19.3078C13.6102 19.2956 13.6188 19.2852 13.6297 19.2778C13.6407 19.2705 13.6536 19.2666 13.6668 19.2666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6665 19.2666C13.6842 19.2666 13.7011 19.2736 13.7136 19.2861C13.7261 19.2986 13.7332 19.3156 13.7332 19.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99844 23.2666C8.99003 23.2664 8.98168 23.2679 8.97384 23.271C8.96601 23.274 8.95886 23.2786 8.95279 23.2844C8.94672 23.2902 8.94186 23.2972 8.93848 23.3049C8.93511 23.3126 8.93328 23.3209 8.93311 23.3293V23.3333C8.93311 23.3462 8.93694 23.3588 8.94412 23.3696C8.9513 23.3803 8.9615 23.3887 8.97344 23.3936C8.98537 23.3986 8.99851 23.3999 9.01118 23.3974C9.02386 23.3948 9.0355 23.3886 9.04464 23.3795C9.05377 23.3703 9.06 23.3587 9.06252 23.346C9.06504 23.3334 9.06374 23.3202 9.0588 23.3083C9.05385 23.2963 9.04548 23.2861 9.03474 23.279C9.02399 23.2718 9.01136 23.2679 8.99844 23.2679H8.99711"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.06546 23.3333C9.06546 23.3465 9.06155 23.3593 9.05423 23.3703C9.0469 23.3813 9.03649 23.3898 9.02431 23.3949C9.01213 23.3999 8.99872 23.4012 8.98579 23.3987C8.97286 23.3961 8.96098 23.3897 8.95166 23.3804C8.94233 23.3711 8.93598 23.3592 8.93341 23.3463C8.93084 23.3333 8.93216 23.3199 8.9372 23.3078C8.94225 23.2956 8.95079 23.2852 8.96176 23.2778C8.97272 23.2705 8.98561 23.2666 8.9988 23.2666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99854 23.2666C9.01622 23.2666 9.03317 23.2736 9.04568 23.2861C9.05818 23.2986 9.0652 23.3156 9.0652 23.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7334 23.3333C13.7334 23.3465 13.7295 23.3593 13.7222 23.3703C13.7149 23.3813 13.7045 23.3898 13.6923 23.3949C13.6801 23.3999 13.6667 23.4012 13.6538 23.3987C13.6408 23.3961 13.6289 23.3897 13.6196 23.3804C13.6103 23.3711 13.604 23.3592 13.6014 23.3463C13.5988 23.3333 13.6001 23.3199 13.6052 23.3078C13.6102 23.2956 13.6188 23.2852 13.6297 23.2778C13.6407 23.2705 13.6536 23.2666 13.6668 23.2666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6665 23.2666C13.6842 23.2666 13.7011 23.2736 13.7136 23.2861C13.7261 23.2986 13.7332 23.3156 13.7332 23.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3985 15.3333C18.3985 15.3465 18.3946 15.3593 18.3872 15.3703C18.3799 15.3813 18.3695 15.3898 18.3573 15.3949C18.3451 15.3999 18.3317 15.4012 18.3188 15.3987C18.3059 15.3961 18.294 15.3897 18.2847 15.3804C18.2753 15.3711 18.269 15.3592 18.2664 15.3463C18.2638 15.3333 18.2652 15.3199 18.2702 15.3078C18.2753 15.2956 18.2838 15.2852 18.2948 15.2778C18.3057 15.2705 18.3186 15.2666 18.3318 15.2666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.332 15.2666C18.3497 15.2666 18.3667 15.2736 18.3792 15.2861C18.3917 15.2986 18.3987 15.3156 18.3987 15.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
