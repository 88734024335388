import React from 'react'

export const ShieldMoney = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M26.667 14.909c0 6.055-4.551 11.716-10.667 13.09-6.116-1.374-10.667-7.035-10.667-13.09V9.657a2.667 2.667 0 011.658-2.468l6.666-2.728a6.183 6.183 0 014.686 0l6.666 2.728a2.666 2.666 0 011.658 2.468v5.252zM16 11.333V10M16 19.334v1.333"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.685 18.38c.346.565.935.956 1.648.956h1.462a1.872 1.872 0 00.454-3.69l-2.5-.627a1.872 1.872 0 01.456-3.687h1.462c.71 0 1.3.39 1.644.953"
    />
  </svg>
)
