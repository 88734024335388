import React from 'react'
import { Colors } from 'utils/colors'

export const Money = props => {
  const { color = Colors.Green } = props || {}
  return (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7324_53510)">
      <path
        d="M25.3333 27.8665H6.53333C5.86145 27.8665 5.21709 27.5996 4.742 27.1245C4.2669 26.6494 4 26.0051 4 25.3332V13.1998C4 12.528 4.2669 11.8836 4.742 11.4085C5.21709 10.9334 5.86145 10.6665 6.53333 10.6665H25.4667C26.1385 10.6665 26.7829 10.9334 27.258 11.4085C27.7331 11.8836 28 12.528 28 13.1998V25.1998C28 25.9071 27.719 26.5854 27.219 27.0855C26.7189 27.5856 26.0406 27.8665 25.3333 27.8665Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3337 6.6665H6.66699"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 2.6665H8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6758 15.8332V14.6665"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6758 22.8335V24.0002"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6517 22.0001C13.9543 22.4947 14.4703 22.8374 15.093 22.8374H16.3717C16.7729 22.8397 17.1609 22.6946 17.4622 22.4296C17.7635 22.1647 17.957 21.7983 18.0061 21.4001C18.0551 21.0019 17.9562 20.5996 17.7281 20.2695C17.5001 19.9394 17.1588 19.7045 16.769 19.6094L14.5823 19.0601C14.1925 18.965 13.8512 18.7301 13.6232 18.4C13.3952 18.0699 13.2963 17.6675 13.3453 17.2693C13.3943 16.8711 13.5878 16.5048 13.8891 16.2398C14.1904 15.9749 14.5785 15.8298 14.9797 15.8321H16.2583C16.881 15.8321 17.3957 16.1721 17.6983 16.6654"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7324_53510">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)}
