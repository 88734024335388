import React from 'react'
import { Colors } from 'utils/colors'

export const DoneCheckCircle = props => {
  const { color = Colors.Green } = props || {}
  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16 28a12 12 0 110-24 12 12 0 010 24v0z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M21.333 13.334l-6.666 6.667-4-4"
    />
  </svg>
)}