import React from 'react'
import { Colors } from 'utils/colors'

export const HandshakeSquare = props => {
  const { color = Colors.Green } = props || {}
  return (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 21.7214H7.112C7.34546 21.7215 7.57666 21.6757 7.7924 21.5864C8.00813 21.4972 8.20418 21.3664 8.36933 21.2014C8.53434 21.0362 8.66519 20.8402 8.75441 20.6244C8.84364 20.4087 8.88948 20.1775 8.88933 19.944V12.832C8.88948 12.5986 8.84364 12.3674 8.75441 12.1516C8.66519 11.9359 8.53434 11.7398 8.36933 11.5747C8.20418 11.4097 8.00813 11.2788 7.7924 11.1896C7.57666 11.1004 7.34546 11.0545 7.112 11.0547H4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.0003 11.0547H24.8896C24.6562 11.0545 24.425 11.1004 24.2092 11.1896C23.9935 11.2788 23.7975 11.4097 23.6323 11.5747C23.4673 11.7398 23.3364 11.9359 23.2472 12.1516C23.158 12.3674 23.1122 12.5986 23.1123 12.832V19.944C23.1122 20.1775 23.158 20.4087 23.2472 20.6244C23.3364 20.8402 23.4673 21.0362 23.6323 21.2014C23.7975 21.3664 23.9935 21.4972 24.2092 21.5864C24.425 21.6757 24.6562 21.7215 24.8896 21.7214H28.0003"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.1387 20.2507H21.992C21.6106 20.2504 21.2336 20.3323 20.8867 20.4907L15.2773 23.0454"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1936 12.2786C13.8599 12.0811 13.487 11.9591 13.1012 11.9213C12.7153 11.8835 12.3258 11.9309 11.9602 12.06L8.89355 13.1653"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3249 11.9693L19.4902 10.2813C19.0856 10.1033 18.6432 10.0286 18.2026 10.064C17.762 10.0994 17.3372 10.2437 16.9662 10.484L13.4862 12.7387C13.2282 12.9059 13.012 13.1302 12.8543 13.3942C12.6967 13.6582 12.6018 13.9548 12.5769 14.2613C12.552 14.5678 12.5979 14.8759 12.7109 15.1619C12.824 15.4478 13.0012 15.704 13.2289 15.9107C13.5219 16.176 13.8866 16.349 14.2775 16.4081C14.6683 16.4671 15.0679 16.4096 15.4262 16.2427L17.5009 15.2747"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5014 15.2734L18.5548 16.2294C18.8298 16.4786 19.0497 16.7824 19.2006 17.1215C19.3515 17.4605 19.4299 17.8273 19.4309 18.1984C19.4319 18.5695 19.3555 18.9367 19.2064 19.2766C19.0574 19.6164 18.8391 19.9215 18.5654 20.1721L15.8761 22.6334C15.3862 23.0818 14.7467 23.3313 14.0826 23.333C13.4185 23.3347 12.7776 23.0886 12.2854 22.6428L10.4374 20.9668C9.99852 20.5766 9.44313 20.3423 8.85742 20.3001"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6667 4H9.33333C6.38781 4 4 6.38781 4 9.33333V22.6667C4 25.6122 6.38781 28 9.33333 28H22.6667C25.6122 28 28 25.6122 28 22.6667V9.33333C28 6.38781 25.6122 4 22.6667 4Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)}