import React from 'react'

export const DollarCircle = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M24.473 22.783c4.703-4.62 4.703-12.032 0-16.652a12.093 12.093 0 00-16.946 0c-4.702 4.62-4.703 12.032 0 16.652M5.467 28H26.8 5.467v0zM16 8v1.667M16 21.334v-1.667"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.316 12a2.493 2.493 0 00-2.483-2.333h-1.826a2.34 2.34 0 00-.57 4.61l3.124.785a2.34 2.34 0 01-.569 4.61h-1.827a2.493 2.493 0 01-2.484-2.338"
    />
  </svg>
)
