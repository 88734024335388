import React from 'react'

export const DollarMonitor = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M24.572 22.667H7.428A3.428 3.428 0 014 19.239V7.428A3.427 3.427 0 017.428 4h17.143A3.427 3.427 0 0128 7.428v11.81a3.428 3.428 0 01-3.428 3.429v0zM18.667 22.667L19.333 28M13.333 22.667L12.667 28M10.857 28h10.286M16 9.333V8M16 17.337v1.33"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M13.685 16.381c.346.565.935.957 1.648.957h1.462a1.872 1.872 0 00.454-3.688l-2.5-.626a1.873 1.873 0 01.456-3.691h1.462c.71 0 1.298.39 1.645.952"
    />
  </svg>
)
