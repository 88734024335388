import React from 'react'

export const PercentageCircle = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00941 18.6254L4.95074 20.684C3.10141 16.3134 3.94941 11.0734 7.51207 7.51205C11.8401 3.18405 18.6467 2.86005 23.3547 6.52405"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.64526 25.4774C13.3559 29.14 20.1613 28.8187 24.4879 24.4894C28.0493 20.928 28.8986 15.688 27.0493 11.3174L24.9906 13.376"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1987 18.8L18.7987 13.2"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0626 18.5946C19.0936 18.6255 19.1182 18.6622 19.135 18.7025C19.1518 18.7429 19.1604 18.7862 19.1604 18.83C19.1604 18.8737 19.1518 18.917 19.135 18.9574C19.1182 18.9978 19.0936 19.0345 19.0626 19.0653C19.0318 19.0963 18.9951 19.1209 18.9547 19.1377C18.9143 19.1545 18.871 19.1631 18.8273 19.1631C18.7836 19.1631 18.7402 19.1545 18.6999 19.1377C18.6595 19.1209 18.6228 19.0963 18.592 19.0653C18.561 19.0345 18.5364 18.9978 18.5196 18.9574C18.5028 18.917 18.4941 18.8737 18.4941 18.83C18.4941 18.7862 18.5028 18.7429 18.5196 18.7025C18.5364 18.6622 18.561 18.6255 18.592 18.5946C18.6228 18.5636 18.6595 18.539 18.6999 18.5223C18.7402 18.5055 18.7836 18.4968 18.8273 18.4968C18.871 18.4968 18.9143 18.5055 18.9547 18.5223C18.9951 18.539 19.0318 18.5636 19.0626 18.5946"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4081 12.9346C13.4391 12.9655 13.4637 13.0021 13.4805 13.0425C13.4973 13.0829 13.5059 13.1262 13.5059 13.1699C13.5059 13.2137 13.4973 13.257 13.4805 13.2974C13.4637 13.3378 13.4391 13.3744 13.4081 13.4053C13.3772 13.4363 13.3406 13.4609 13.3002 13.4777C13.2598 13.4944 13.2165 13.5031 13.1727 13.5031C13.129 13.5031 13.0857 13.4944 13.0453 13.4777C13.0049 13.4609 12.9683 13.4363 12.9374 13.4053C12.9064 13.3744 12.8818 13.3378 12.865 13.2974C12.8482 13.257 12.8396 13.2137 12.8396 13.1699C12.8396 13.1262 12.8482 13.0829 12.865 13.0425C12.8818 13.0021 12.9064 12.9655 12.9374 12.9346C12.9683 12.9036 13.0049 12.879 13.0453 12.8622C13.0857 12.8454 13.129 12.8368 13.1727 12.8368C13.2165 12.8368 13.2598 12.8454 13.3002 12.8622C13.3406 12.879 13.3772 12.9036 13.4081 12.9346"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
