import React from 'react'

export const AvoidCard = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4 9.332h24M12.76 14.668H9.333M12 22.667H8a4 4 0 01-4-4V8a4 4 0 014-4h16a4 4 0 014 4v5.333"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M29.333 22.61a6.713 6.713 0 01-6.722 6.722 6.712 6.712 0 01-6.723-6.723 6.71 6.71 0 016.723-6.722 6.711 6.711 0 016.722 6.722v0zM17.859 27.363l9.504-9.504"
    />
  </svg>
)
