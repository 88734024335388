import React from 'react'
import { Colors } from 'utils/colors'

export const Home =props => {
  const { color = Colors.Green } = props || {}
  return (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.667 14.0615V28.0002"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3337 16.0002L16.8337 6.91356C16.6062 6.74884 16.3325 6.66016 16.0517 6.66016C15.7708 6.66016 15.4971 6.74884 15.2697 6.91356L2.66699 16.0002"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0003 12.9903V5.99967C26.0003 5.82286 25.9301 5.65329 25.8051 5.52827C25.68 5.40325 25.5105 5.33301 25.3337 5.33301H21.3337C21.1568 5.33301 20.9873 5.40325 20.8623 5.52827C20.7372 5.65329 20.667 5.82286 20.667 5.99967V9.47834"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3337 28H2.66699"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 14.0771V27.9998"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6667 20H18.6667C18.2985 20 18 20.2985 18 20.6667V23.3333C18 23.7015 18.2985 24 18.6667 24H22.6667C23.0349 24 23.3333 23.7015 23.3333 23.3333V20.6667C23.3333 20.2985 23.0349 20 22.6667 20Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33301 28V20.6667C9.33301 20.4899 9.40325 20.3203 9.52827 20.1953C9.65329 20.0702 9.82286 20 9.99967 20H13.333C13.5098 20 13.6794 20.0702 13.8044 20.1953C13.9294 20.3203 13.9997 20.4899 13.9997 20.6667V28"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)}
