import React from 'react'

export const ProfileCheckMark = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17.455 12.912a3 3 0 11-4.242 4.242 3 3 0 014.242-4.242M20.888 24a3.863 3.863 0 00-3.613-2.516h-3.883A3.863 3.863 0 009.78 24M25.333 12a5.333 5.333 0 110-10.667 5.333 5.333 0 010 10.667z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M27.704 5.481l-2.962 2.963-1.78-1.777M20 5.333H9.333A5.333 5.333 0 004 10.666v12A5.333 5.333 0 009.333 28h12a5.333 5.333 0 005.334-5.334V12"
    />
  </svg>
)
