import React from 'react'
import { Colors } from 'utils/colors'

export const Target = props => {
  const { color = Colors.Green } = props || {}
  return (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7197 13.28L15.373 16.6267"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.667 8.00008V13.3334H24.0003L29.3337 8.00008L25.7777 6.22275L24.0003 2.66675L18.667 8.00008Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.667 5.30005C12.4835 5.43406 10.3852 6.19566 8.6241 7.49335C6.86299 8.79103 5.51402 10.5696 4.73926 12.6154C3.96449 14.6612 3.79689 16.8871 4.25658 19.0259C4.71627 21.1646 5.78371 23.1251 7.33064 24.6719C8.87758 26.2187 10.8382 27.2859 12.977 27.7453C15.1158 28.2048 17.3417 28.0369 19.3874 27.2619C21.4331 26.487 23.2116 25.1378 24.509 23.3765C25.8065 21.6153 26.5679 19.5169 26.7017 17.3334"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6136 17.3334C21.4846 18.5141 21.0246 19.6343 20.2864 20.5648C19.5483 21.4953 18.5621 22.1982 17.4417 22.5924C16.3213 22.9866 15.1123 23.056 13.9542 22.7927C12.796 22.5294 11.7359 21.9441 10.896 21.1043C10.0562 20.2644 9.47084 19.2043 9.20754 18.0461C8.94424 16.8879 9.01369 15.6789 9.40789 14.5585C9.8021 13.4381 10.505 12.452 11.4355 11.7139C12.366 10.9757 13.4862 10.5157 14.6669 10.3867"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)}
