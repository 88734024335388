import React from 'react'
import { Colors } from 'utils/colors'

export const PersonPresenting = props => {
  const { color = Colors.Green } = props || {}
  return (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12.0007V8.00065C4 7.29341 4.28095 6.61513 4.78105 6.11503C5.28115 5.61494 5.95942 5.33398 6.66667 5.33398H25.3333C26.0406 5.33398 26.7189 5.61494 27.219 6.11503C27.719 6.61513 28 7.29341 28 8.00065V24.0006C28 24.7079 27.719 25.3862 27.219 25.8863C26.7189 26.3864 26.0406 26.6673 25.3333 26.6673H17.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0003 26.6673L11.6657 25.8273C11.4377 25.2551 11.0433 24.7644 10.5334 24.4187C10.0235 24.0731 9.42165 23.8885 8.80566 23.8887H5.86166C5.2455 23.8877 4.6433 24.0721 4.13327 24.4178C3.62325 24.7635 3.22897 25.2546 3.00166 25.8273L2.66699 26.6673"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.9828 16.6844C9.42041 17.1224 9.66611 17.7162 9.66586 18.3353C9.66561 18.9545 9.41943 19.5481 8.98147 19.9857C8.54351 20.4233 7.94964 20.669 7.33052 20.6688C6.7114 20.6685 6.11774 20.4224 5.68013 19.9844C5.46345 19.7675 5.2916 19.5101 5.1744 19.2269C5.05721 18.9436 4.99695 18.64 4.99707 18.3335C4.99732 17.7143 5.24351 17.1207 5.68147 16.6831C6.11943 16.2455 6.71329 15.9998 7.33241 16C7.95153 16.0003 8.54519 16.2464 8.9828 16.6844"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.667 20V12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.667 19.9997V15.5664"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.667 19.9999V12.7266"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)}
