import React from 'react'
import { Colors } from 'utils/colors'

export const Pencil = props => {
  const { color = Colors.Green } = props || {}
  return (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20L16.2 19.4814C16.4951 19.4452 16.7696 19.3115 16.98 19.1014L27.1547 8.93338C27.6957 8.39207 27.9996 7.65806 27.9996 6.89272C27.9996 6.12738 27.6957 5.39336 27.1547 4.85205C26.6134 4.31101 25.8793 4.00708 25.114 4.00708C24.3487 4.00708 23.6146 4.31101 23.0733 4.85205L12.9933 14.9321C12.7892 15.1361 12.6568 15.4009 12.616 15.6867L12 20Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3333 5.33325H9.33333C7.91885 5.33325 6.56229 5.89515 5.5621 6.89535C4.5619 7.89554 4 9.2521 4 10.6666V22.6666C4 24.0811 4.5619 25.4376 5.5621 26.4378C6.56229 27.438 7.91885 27.9999 9.33333 27.9999H21.3333C22.7478 27.9999 24.1044 27.438 25.1046 26.4378C26.1048 25.4376 26.6667 24.0811 26.6667 22.6666V14.6666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)}
