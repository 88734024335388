import React from 'react'

export const Car = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3334 12L25.8401 13.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.17341 13.3333L2.66675 12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3333 18.6667H21"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0001 18.6667H7.66675"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8388 13.3334L24.7201 7.98404C24.563 7.23465 24.1529 6.56212 23.5585 6.07944C22.9641 5.59676 22.2218 5.33332 21.4561 5.33337H10.6828C9.93069 5.33349 9.20076 5.58793 8.61158 6.05535C8.0224 6.52278 7.60861 7.17571 7.43743 7.90804L6.16943 13.3334"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.33341 26.6667H7.33341C7.86385 26.6667 8.37256 26.456 8.74763 26.0809C9.1227 25.7058 9.33341 25.1971 9.33341 24.6667V23.3334H22.6667V24.6667C22.6667 25.1971 22.8775 25.7058 23.2525 26.0809C23.6276 26.456 24.1363 26.6667 24.6667 26.6667H27.6667C28.5867 26.6667 29.3334 25.92 29.3334 25V17.3334C29.3334 16.2725 28.912 15.2551 28.1618 14.5049C27.4117 13.7548 26.3943 13.3334 25.3334 13.3334H6.66675C5.60588 13.3334 4.58847 13.7548 3.83832 14.5049C3.08818 15.2551 2.66675 16.2725 2.66675 17.3334V25C2.66675 25.92 3.41341 26.6667 4.33341 26.6667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
